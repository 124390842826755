import React, { useRef } from 'react';
import { AddressSuggestion } from '@/shared/api/getAddressSuggestion/types';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cx } from 'class-variance-authority';
import { Field, FieldHookConfig, useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useMedia } from 'react-use';
import useMeasure from 'react-use-measure';
import { Dropdown } from '../Dropdown/Dropdown';
import { cvaCheckbox, cvaList, cvaListWrapper, cvaOverlay, cvaScroll } from '../Dropdown/DropdownStyles';
import { cvaInner as cvaInnerSelectable, cvaTitle } from '../Selectable/SelectableStyles';
import { SimpleBar } from '../SimpleBar';
import {
  // cvaCaretDown,
  cvaError,
  cvaInner,
  cvaInput,
  cvaLabel,
  cvaPlaceholder,
  cvaWrapper,
} from './InputStyles';
// import IconCaretDown from '/public/icons/arrow-down.svg';
import IconClose from '/public/icons/close2.svg';

const formatChars = {
  '#': '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9]',
};

interface InputProps {
  className?: string;
  inputClassName?: string;
  label?: string;
  isPhoneField?: boolean;
  placeholder?: string;
  mask?: string;
  as?: 'select' | 'input' | 'textarea';
  color?: 'white' | 'gray';
  options?: {
    label: number | string;
    value: number | string | Date;
  }[];
  customError?: string;
  onChange?: (e: any) => void;
  suggestions?: AddressSuggestion[];
  onSuggestionSelect?: (suggestion: AddressSuggestion) => void;
  isAutoFocusWithoutHouse?: boolean;
}

export const Input = ({
  className,
  as = 'input',
  label,
  options,
  placeholder,
  mask,
  required,
  inputClassName,
  isPhoneField = false,
  color = 'gray',
  customError = '',
  onChange,
  suggestions = [],
  onSuggestionSelect,
  isAutoFocusWithoutHouse = false,
  ...props
}: InputProps & FieldHookConfig<string>) => {
  const refInput = useRef<HTMLInputElement>();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [field, meta] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  const isMobile = useMedia('(max-width: 767px)', true);
  const [buttonRef, buttonMeasure] = useMeasure({
    scroll: true,
  });

  return (
    <div className={twMergeExt(className, cvaWrapper())} ref={buttonRef}>
      <div className={twMergeExt(cvaInner())}>
        {label ? <div className={cvaLabel()}>{label}</div> : null}
        {placeholder && !field.value ? (
          <div
            className={cvaPlaceholder({
              hasError: Boolean((meta.touched && meta.error) || customError),
              focused: isFocused || Boolean(field.value),
            })}
          >
            {placeholder}&nbsp;
            {required ? <span>*</span> : ''}
          </div>
        ) : null}
        {/* {as == 'select' ? (
          <IconCaretDown className={cvaCaretDown()} />
        ) : null} */}
        {mask ? (
          <InputMask
            {...field}
            mask={mask}
            onBlur={() => onBlur()}
            onFocus={() => onFocus()}
            className={twMergeExt(
              cvaInput({
                color: color,
                hasError: Boolean((meta.touched && meta.error) || customError),
                isFocused: isFocused,
              }),
              inputClassName
            )}
            type={props.type}
          />
        ) : isPhoneField ? (
          <InputMask
            {...field}
            inputMode="numeric"
            // ref={inputRef}
            // value={phone}
            // disabled={disabled}
            mask={`+7 (###) ### ## ##`}
            //@ts-expect-error todo
            formatChars={formatChars}
            onBlur={() => onBlur()}
            onFocus={() => onFocus()}
            className={twMergeExt(
              cvaInput({
                color: color,
                hasError: Boolean((meta.touched && meta.error) || customError),
                isFocused: isFocused,
              }),
              inputClassName
            )}
            type={props.type}
          />
        ) : as !== 'select' ? (
          <div className="relative">
            <Field
              {...field}
              innerRef={(el: HTMLInputElement) => (refInput.current = el)}
              as={as}
              value={field.value || ''}
              className={twMergeExt(
                cvaInput({
                  textarea: as === 'textarea' ? true : false,
                  color: color,
                  hasError: Boolean((meta.touched && meta.error) || customError),
                  isFocused: isFocused,
                }),
                inputClassName
              )}
              onBlur={(e) => {
                onBlur();
                props.onBlur && props.onBlur(e);
                setTimeout(() => setShowSuggestions(false), 200);
              }}
              onFocus={(e) => {
                onFocus();
                props.onFocus && props.onFocus(e);
                setShowSuggestions(true);
              }}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
                setShowSuggestions(true);
              }}
              type={props.type || 'text'}
            />
            <AnimatePresence>
              {showSuggestions && suggestions.length > 0 && suggestions.length > 0 ? (
                <>
                  <div className={cvaOverlay()} onClick={() => setShowSuggestions(false)}></div>
                  <motion.div
                    className={twMergeExt(
                      cvaListWrapper({
                        place: 'right',
                        multiselect: false,
                      })
                    )}
                    initial={{
                      y: '0.2rem',
                      opacity: 0,
                    }}
                    animate={{
                      y: '1.2rem',
                      opacity: 1,
                    }}
                    exit={{
                      y: '0.2rem',
                      opacity: 0,
                    }}
                    transition={{
                      duration: 0.3,
                      ease: 'easeOut',
                    }}
                    style={{
                      top: isMobile ? 'auto' : buttonMeasure.height,
                      bottom: isMobile ? 0 : 'auto',
                    }}
                  >
                    {isMobile ? (
                      <div className="py-1.4 px-2.4 bg-cLightGrey rounded-tl-[2rem] rounded-tr-[2rem] flex items-center justify-between">
                        <div></div>
                        <span className="text-labelL">{placeholder}</span>
                        <IconClose onClick={() => setShowSuggestions(false)} className="w-2.4 h-2.4 fill-cBlack" />
                      </div>
                    ) : null}
                    <SimpleBar data-lenis-prevent className={cvaScroll()}>
                      <div className={cvaList()}>
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            className={cx(
                              cvaCheckbox(),
                              cvaInnerSelectable({
                                inDropdown: true,
                              }),
                              cvaTitle(),
                              'cursor-pointer hover:bg-cMediumGreyOnWhite'
                            )}
                            onClick={() => {
                              field.onChange({
                                target: {
                                  name: field.name,
                                  value: suggestion.value,
                                },
                              });
                              if (isAutoFocusWithoutHouse && !suggestion.data.house && refInput.current) {
                                refInput.current.focus();
                              }
                              onSuggestionSelect && onSuggestionSelect(suggestion);
                              setShowSuggestions(false);
                            }}
                          >
                            {suggestion.value}
                          </li>
                        ))}
                      </div>
                    </SimpleBar>
                  </motion.div>
                </>
              ) : null}
            </AnimatePresence>
          </div>
        ) : (
          <>
            <Dropdown
              name={field.name}
              buttonClassName={twMergeExt(
                cvaInput({
                  select: true,
                  color: color,
                  hasError: Boolean((meta.touched && meta.error) || customError),
                  isFocused: isFocused,
                }),
                inputClassName
              )}
              arrowClassName={'absolute right-2.4 sm:top-1.5 top-1'}
              onOpen={(val) => (val ? onFocus() : onBlur())}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              data={options?.map((option, index) => ({
                id: index,
                value: option.value,
                label: option.label,
              }))}
              value={field.value}
              place={'right'}
              multiselect={false}
              title={placeholder}
              // placeholder={placeholder}
              // className={cvaSortDropdown()}
            />
          </>
        )}
      </div>
      {meta.touched && meta.error ? (
        <div
          className={cvaError({
            isFocused: isFocused,
          })}
        >
          {meta.error}
        </div>
      ) : null}
      {customError ? (
        <div
          className={cvaError({
            isFocused: isFocused,
          })}
        >
          {customError}
        </div>
      ) : null}
    </div>
  );
};
